import { http } from "../plugin/http2-common";

class RecommendationService {
  listRecommendations(surveyId) {
    return http.get("/recommendation/list/" + surveyId);
  }
  listCategory(surveyId) {
    return http.get("/recommendation/list-category/" + surveyId);
  }
  select(id){
    return http.get(`/recommendation/select/${id}`);
  }
  update(data) {
    return http.put(`/recommendation/update`, data);
  }
}

export default new RecommendationService();
