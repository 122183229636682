<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div v-if="type==='textarea'" :class="'main '+mainClassName">
      <textarea-autosize
        v-model="applyValue"
        :class="className"
        :rows="rows"
        :maxlength="maxlength + additionalTextCount"
        :dir="dir"
        :placeholder="placeholder"
        :autosize="autosize"
        :disabled="disabled"
        @focusout.native="focusOut()"
      />
      <span>{{ applyValue.length - additionalTextCount }}/{{ maxlength }}</span>
    </div>
    <div v-if="type === 'label'" :class="mainClassName" :style="customStyle">
      <!-- <span :class="spanClassName">{{ value }}</span> -->
      <textarea-autosize
        v-model="value"
        :class="className"
        :dir="dir"
        :disabled="true"
        :rows="1"
        style="border: none; background: transparent; width: calc(100% - 2px);color: #000;"
      />
    </div>
    <div v-if="type === 'html'" :class="mainClassName" :style="customStyle">
      <span :class="spanClassName" v-html="value" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomEditor",
  components:{
  
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    type: {
      type: String,
      default: "label",
    },
    maxlength: {
      type: Number,
      default: 500
    },
    className: {
      type: String,
      default: ""
    },
    mainClassName: {
      type: String,
      default: ""
    },
    spanClassName: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: "",
    },
    cusStyle: {
      type: String,
      default: "",
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const {code, name} = this.$store.state.survey.language;
    const isArabic = code === "ar" && name === "Arabic";
    return {
      mutateValue: this.value,
      dir: isArabic ? "rtl" : "ltr",
      customStyle: isArabic ? this.cusStyle+"text-align: end" : this.cusStyle+"text-align: start"
    }
  },
  computed: {
    applyValue: {
      get: function () {
        return this.mutateValue;
      },
      set: function (newValue) {
        this.mutateValue = newValue;
      },
    },
    additionalTextCount: {
      get: function () {
        var count = 0;
        const openBracketMatches = this.applyValue.match(/{/g);
        if (openBracketMatches !== null) {
          count += openBracketMatches.length;
        }
        const closedBracketMatches = this.applyValue.match(/}/g);
        if (closedBracketMatches !== null) {
          count += closedBracketMatches.length;
        }
        return count;
      }
    }
  },
  methods: {
    focusOut() {
      this.$emit("input", this.applyValue);
    },
  },
};
</script>

<style scoped>
  .main {
    position: relative;
    display: inline-block;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    background-color: #fff;
  }

  .main textarea {
    border: none!important;
    margin-bottom: 20px;
    width: 100%;
    padding: 0.375rem 0.75rem;
  }

  .main textarea:focus, .main textarea:active {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }

  .main span {
    color: #768192;
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 10px;
  }
  .category-name {
    color: #006db6!important;
    font-weight: bold;
  }

  .category-name textarea{
    color: #006db6!important;
    font-weight: bold;
  }
  td .main {
    width: 100%;
  }
  .disclaimer-name textarea {
  font-weight: bold !important;
}
</style>