export default {
    clone: function(arrays) {
        if (!Array.isArray(arrays)) {
            return [];
        }
        return arrays.map(item => { return {...item} });
    },
    cloneAndRemoveProperty: function(arrays, property) {
        if (!property) {
            return this.clone(arrays);
        }
        if (!Array.isArray(arrays)) {
            return [];
        }
        return arrays.map(item => { 
            const cloneItem = {...item}
            delete cloneItem[property]
            return cloneItem
        });
    },
    isEmpty: function(array) {
        return !Array.isArray(array) || array.length == 0;
    }
}