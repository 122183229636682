<template>
  <div class="dropdown" tabindex="0" @focusout="hideDrop()">
    <div
      :class="'dropdown-select'"
      @click="drop()"
    >
      <!-- <span v-if="consumer" :class="'select '+spanClassName">{{ selectedConsumername }} ({{ selectedName }})</span> -->
      <textarea-autosize
        v-if="consumer"
        v-model="data"
        :set="data=selectedConsumername+' ('+selectedName+')'"
        :dir="dir"
        :disabled="true"
        :rows="1"
        style="border: none; background: transparent; width: 100%;color: #000"
      />
      <!-- <span v-else :class="'select '+spanClassName">{{ selectedName }}</span> -->
      <textarea-autosize
        v-else
        v-model="selectedName"
        :dir="dir"
        :disabled="true"
        :rows="1"
        style="border: none; background: transparent; width: 100%;color: #000"
      />
      <b-icon icon="caret-down-fill" style="color: #C4C4C4" />
    </div>
    <div v-if="show">
      <div
        v-if="detail"
        class="dropdown-list"
      >
        <div
          class="dropdown-list__item"
          style="border-bottom: 1px solid black"
          @click="back()"
        >
          <span>&lt;&nbsp;{{ dropdown.name }}</span>
        </div>
        <!-- <div 
          v-for="item in dropdown.products" 
          :key="item.id"
          class="dropdown-list__item"
          @click="select(item.name, item.consumerName, item.id)"
        >
          <span v-if="consumer">{{ item.consumerName }} ({{ item.name }})</span>
          <span v-else>{{ item.name }}</span>
        </div> -->
        <template 
          v-for="item in dropdown.products"
        >
          <div v-if="!item.isDisabled"
               :key="item.id"
               class="dropdown-list__item"
               @click="select(item.name, item.consumerName, item.id)"
          >
            <span v-if="consumer">{{ item.consumerName }} ({{ item.name }})</span>
            <span v-else>{{ item.name }}</span>
          </div>
        </template>
      </div>
      <div
        v-else
        class="dropdown-list"
      >
        <div 
          v-for="item in categories" 
          :key="item.id"
          class="dropdown-list__item"
          @click="choose(item.id)"
        >
          <span>{{ item.name }}</span>
          <span>&gt;</span>
        </div>
        <div 
          v-if="allowNoRecommendation"
          :key="-1"
          class="dropdown-list__item"
          @click="select(remove_recommendation_text, remove_recommendation_text, -1)"
        >
          <span>Remove Recommendation</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "../../common/Constants";
  export default {
    name: 'Dropdown',
    props: {
      product: {
        type: Object,
        required: true
      },
      recommendation: {
          type: Number,
          required: true
      },
      categories: {
          type: Array,
          required: true
      },
      consumer: {
        type: Boolean,
        default: false
      },
      allowNoRecommendation: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const {code, name} = this.$store.state.survey.language;
      const isArabic = code === "ar" && name === "Arabic";
      return {
        remove_recommendation_text: Constants.REMOVE_RECOMMENDATION_TEXT,
        detail: false,
        show: false,
        edit: false,
        selectedConsumername: this.product.consumerName,
        selectedName: this.product.name,
        categoryId: '',
        cname: '',
        dropdown: [],
        spanClassName: isArabic ? "pr-1" : "",
        dir: isArabic ? "rtl" : "ltr",
      }
    },
    mounted(){
    },
    methods: {
      select (name, consumerName, id) {
        // if(name.startsWith("E-Life Pro Read")) {
        //   name = "E-Life Pro Read";
        // }
        this.$emit('select', name, consumerName, id, this.recommendation)
        this.selectedConsumername = consumerName
        this.selectedName = name
        this.detail = false
        this.show = false 
      },
      choose (id) {
        this.detail = true
        this.categoryId = id
        for(var i=0; i < this.categories.length ; i++){
          if(this.categories[i].id == id) {
              this.dropdown = this.categories[i];
              break;
          }
        }
        console.log(this.dropdown.products);
      },
      drop () {
        if (this.show) {
          this.show = false
          this.detail = false
        } else {
          this.show = true
        }
      },
      back () {
        this.detail = false
        
      },
      hideDrop () {
        this.show = false
        this.detail = false
      }
    }
  }
</script>
<style scoped>
.dropdown {
  width: 35rem;
  position: relative;
}
.dropdown-select {
  padding: 0.2rem;
  background-color: white;
  color: black;
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
}
.dropdown-list {
  background-color: rgb(233, 232, 232);
  position: absolute;
  border: 1px solid #C4C4C4;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
}
.dropdown-list__item {
  padding: 0.3rem;
  font-size: 14px;
  color: black;
  min-height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

