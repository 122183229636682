import { http } from "../plugin/http2-common";

class LensConfigService {
    listConfig (surveyId) {
        return http.get("/lens-config/list/" + surveyId);
    }
    update (data) {
        return http.put("/lens-config/update", data);
    }
}

export default new LensConfigService();