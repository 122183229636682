<template>
  <div>
    <div class="common-department">
      <div class="d-flex justify-content-between align-items-center recommendation-header">
        <div>
          <span style="padding: 0.3rem">Product assigned in this section, will affect the survey
            recommendation results</span>
        </div>
      </div>
      <strong style="padding: 0.3rem">SURVEY DONE AT HOME</strong>
      <table
        id="table-1"
        class="table-sm"
        style="width: 100%"
        responsive
        sm
        borderless
      >
        <thead>
          <tr bgcolor="#F6F6F6" :class="{ tr_blue: edit }">
            <th width="35%" :class="isEditContent">
              LENS DESIGN (UNDER THE AGE OF 45, WITH ADDITION <= 1.00)
            </th>
            <th width="65%" :class="isEditContent">
              DEFAULT RECOMMENDATION
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in home_lens_under_45" :key="item.id">
            <td style="padding-right: 5%" :class="{ 'border-top': index > 0 }">
              <strong style="color: #006db6">{{ item.name }}</strong>
            &nbsp;
              <img
                :id="item.name"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" :target="item.name" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ item.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="item.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td :class="{ 'border-top': index > 0 }">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="item.product != null" 
                  v-model="data" 
                  :set="data=item.product.consumerName + ' (' + item.product.name + ')'"
                  style="width: 35rem;"
                />
                <!-- {{ item.product.consumerName }} ({{ item.product.name }}) -->
              </div>
              <div v-else>
                <Dropdown
                  :product="item.product != null ? item.product : ''"
                  :categories="home_lens_design"
                  :recommendation="item.id"
                  :consumer="true"
                  :style="'word-break: break-word;'"
                  @select="homeSelect"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <strong style="padding: 0.3rem">SURVEYS DONE WITH ECP’s</strong>
      <table class="table-sm" style="width: 100%" responsive sm borderless>
        <thead>
          <tr bgcolor="#F6F6F6" :class="{ tr_blue: edit }">
            <th width="35%">
              LENS DESIGN (UNDER THE AGE OF 45 WITH ADDITION <= 1.00)
            </th>
            <th width="65%">
              DEFAULT RECOMMENDATION
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ecp_lens_under_45" :key="item.id">
            <td style="padding-right: 5%" :class="{ 'border-top': index > 0 }">
              <strong style="color: #006db6">{{ item.name }}</strong>
            &nbsp;
              <img
                :id="item.name"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" :target="item.name" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ item.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="item.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td :class="{ 'border-top': index > 0 }">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="item.product != null" 
                  v-model="item.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="item.product != null ? item.product : ''"
                  :categories="single_vision"
                  :recommendation="item.id"
                  @select="ecpSelect"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="table-sm" style="width: 100%" responsive sm borderless>
        <thead>
          <tr bgcolor="#F6F6F6" :class="{ tr_blue: edit }">
            <th width="35%">
              LENS DESIGN (AGE 45 AND ABOVE OR UNDER THE AGE OF 45, WITH ADDITION > 1.00)
            </th>
            <th width="65%">
              DEFAULT RECOMMENDATION
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding-right: 5%">
              <strong style="color: #006db6">{{ highly_optimized_pal.name }}</strong>
            &nbsp;
              <img
                id="highly_optimized_pal"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="highly_optimized_pal" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ highly_optimized_pal.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="highly_optimized_pal.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td>
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="highly_optimized_pal.product != null" 
                  v-model="highly_optimized_pal.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="highly_optimized_pal.product != null ? highly_optimized_pal.product : ''"
                  :categories="pal"
                  :recommendation="highly_optimized_pal.id"
                  @select="palSelect"
                />
              </div>
            </td>
          </tr>
          <tr v-if="elife_pro_pal.id != null">
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ elife_pro_pal.name }}</strong>
            &nbsp;
              <img
                :id="elife_pro_pal.name"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" :target="elife_pro_pal.name" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ elife_pro_pal.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="elife_pro_pal.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="elife_pro_pal.product != null" 
                  v-model="elife_pro_pal.product.name"
                  style="width: 35rem;"
                />
                <CustomEditor 
                  v-else
                  v-model="remove_recommendation_text"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="elife_pro_pal.product != null ? elife_pro_pal.product : {id: -1, consumerName: remove_recommendation_text, name: remove_recommendation_text}"
                  :categories="pal_and_elife"
                  :recommendation="elife_pro_pal.id"
                  :allow-no-recommendation="allowNoRecommendation(elife_pro_pal.id)"
                  @select="elifeSelect"
                />
              </div>
            </td>
          </tr>
          <tr v-for="item in mid_pal_and_degressive_lenses" :key="item.id">
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ item.name }}</strong>
            &nbsp;
              <img
                :id="item.name"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" :target="item.name" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ item.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="item.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="item.product != null" 
                  v-model="item.product.name"
                  style="width: 35rem;"
                />
                <CustomEditor 
                  v-else
                  v-model="remove_recommendation_text"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product=" item.product == null ? {id: -1, consumerName: remove_recommendation_text, name: remove_recommendation_text} : item.product"
                  :categories="e_life"
                  :recommendation="item.id"
                  :allow-no-recommendation="allowNoRecommendation(item.id)"
                  @select="above45Select"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="show_info">
        <span>The following information will be shown on the Final Summary results
          page, for respondents aged 45 and above. It is recommended to include
          the following information when ECPs using this survey have a full
          product portfolio.</span>
        <br /><br />
        <span>Check the boxes to show the info on the results page, or uncheck to
          remove them from the results page:</span>
        <br />
        <div class="d-flex justify-content-start">
          <div>
            <label class="check-box" :class="{ dis_checkbox: edit == false }">H&O Addition
              <input v-model="e_life_config.value" type="checkbox" />
              <span class="checkmark" />
            </label>
          </div>
          <div class="d-flex align-items-center" style="padding-left: 8px">
            <img
              id="H&O Addition"
              class="tooltip-img"
              style="width: 20px; height: 20px"
              src="@/assets/icons/tooltip.png"
            />
            <b-tooltip style="margin-top: -5px"
                       class="btn-tooltip" target="H&O Addition" triggers="hover" placement="bottom" custom-class="custom-tooltip"
            >
              <div class="text-left">
                {{ e_life_config.description }}
              </div>
            </b-tooltip>
            <!-- <b-button
              v-b-tooltip.hover
              style="margin-top: -5px"
              :title="e_life_config.description"
              class="btn-tooltip"
            >
              <img
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
            </b-button> -->
          </div>
        </div>
        
        <div class="d-flex justify-content-start">
          <div>
            <label class="check-box" :class="{ dis_checkbox: edit == false }">Degression Distance
              <input v-model="soltes_distance.value" type="checkbox" :disabled="isDegressionCheckboxDisabled" />
              <span class="checkmark" />
            </label>
          </div>
          <div class="d-flex align-items-center" style="padding-left: 8px">
            <img
              id="Degression Distance"
              class="tooltip-img"
              style="width: 20px; height: 20px"
              src="@/assets/icons/tooltip.png"
            />
            <b-tooltip style="margin-top: -5px"
                       class="btn-tooltip" target="Degression Distance" triggers="hover" placement="bottom" custom-class="custom-tooltip"
            >
              <div class="text-left">
                {{ soltes_distance.description }}
              </div>
            </b-tooltip>
            <!-- <b-button
              v-b-tooltip.hover
              style="margin-top: -5px"
              :title="soltes_distance.description"
              class="btn-tooltip"
            >
              <img
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
            </b-button> -->
          </div>
        </div>
        
        <div class="d-flex justify-content-start">
          <div>
            <label class="check-box" :class="{ dis_checkbox: edit == false }">Soltes calculator
              <input v-model="soltes_calculator.value" type="checkbox" :disabled="isCalculatorCheckboxDisabled" />
              <span class="checkmark" />
            </label>
          </div>
          <div class="d-flex align-items-center" style="padding-left: 8px">
            <img
              id="Soltes calculator"
              class="tooltip-img"
              style="width: 20px; height: 20px"
              src="@/assets/icons/tooltip.png"
            />
            <b-tooltip style="margin-top: -5px"
                       class="btn-tooltip" target="Soltes calculator" triggers="hover" placement="bottom" custom-class="custom-tooltip"
            >
              <div class="text-left">
                {{ soltes_calculator.description }}
              </div>
            </b-tooltip>
            <!-- <b-button
              v-b-tooltip.hover
              style="margin-top: -5px"
              :title="soltes_calculator.description"
              class="btn-tooltip"
            >
              <img
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
            </b-button> -->
          </div>
        </div>
      </div>
      <br />
      <br />
      <table
        class="table-sm"
        style="width: 100%; margin-bottom: 150px"
        responsive
        sm
        borderless
      >
        <thead>
          <tr bgcolor="#F6F6F6" :class="{ tr_blue: edit }">
            <th width="30%">
              LIGHTSTYLE RESULTS (ALL AGES)
            </th>
            <th width="70%">
              DEFAULT RECOMMENDATION
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding-right: 5%">
              <strong style="color: #006db6">{{ protect_from_blue_light.name }}</strong>
            &nbsp;
              <img
                id="protect_from_blue_light"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="protect_from_blue_light" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ protect_from_blue_light.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="protect_from_blue_light.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td>
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="protect_from_blue_light.product != null" 
                  v-model="protect_from_blue_light.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="protect_from_blue_light.product != null ? protect_from_blue_light.product : ''"
                  :categories="coating"
                  :recommendation="protect_from_blue_light.id"
                  @select="blueLightSelect"
                />
              </div>
            </td>
          </tr>
        
          <tr>
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ protect_from_road_glare.name }}</strong>
            &nbsp;
              <img
                id="protect_from_road_glare"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="protect_from_road_glare" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ protect_from_road_glare.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="protect_from_road_glare.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="protect_from_road_glare.product != null" 
                  v-model="protect_from_road_glare.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="protect_from_road_glare.product != null ? protect_from_road_glare.product : ''"
                  :categories="coating"
                  :recommendation="protect_from_road_glare.id"
                  @select="roadGlareSelect"
                />
              </div>
            </td>
          </tr>
        
          <tr>
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ polarized_lenses.name }}</strong>
            &nbsp;
              <img
                id="polarized_lenses"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="polarized_lenses" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ polarized_lenses.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="polarized_lenses.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="polarized_lenses.product != null" 
                  v-model="polarized_lenses.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="polarized_lenses.product != null ? polarized_lenses.product : ''"
                  :categories="polashade"
                  :recommendation="polarized_lenses.id"
                  @select="polashadeSelect"
                />
              </div>
            </td>
          </tr>
        
          <tr>
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ photochromic_solution.name }}</strong>
            &nbsp;
              <img
                id="photochromic_solution"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="photochromic_solution" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ photochromic_solution.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="photochromic_solution.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="photochromic_solution.product != null" 
                  v-model="photochromic_solution.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="photochromic_solution.product != null ? photochromic_solution.product : ''"
                  :categories="transitions"
                  :recommendation="photochromic_solution.id"
                  @select="transitionsSelect"
                />
              </div>
            </td>
          </tr>
        
          <tr>
            <td style="padding-right: 5%" class="border-top">
              <strong style="color: #006db6">{{ contrast_enhancement.name }}</strong>
            &nbsp;
              <img
                id="contrast_enhancement"
                class="tooltip-img"
                style="width: 20px; height: 20px"
                src="@/assets/icons/tooltip.png"
              />
              <b-tooltip style="margin-top: -5px"
                         class="btn-tooltip" target="contrast_enhancement" triggers="hover" placement="bottom" custom-class="custom-tooltip"
              >
                <div class="text-left">
                  {{ contrast_enhancement.description }}
                </div>
              </b-tooltip>
              <!-- <b-button
                v-b-tooltip.hover
                style="margin-top: -5px"
                :title="contrast_enhancement.description"
                class="btn-tooltip"
              >
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/icons/tooltip.png"
                />
              </b-button> -->
            </td>
            <td class="border-top">
              <div v-if="edit == false" style="padding: 0.21rem">
                <CustomEditor 
                  v-if="contrast_enhancement.product != null" 
                  v-model="contrast_enhancement.product.name"
                  style="width: 35rem;"
                />
              </div>
              <div v-else>
                <Dropdown
                  :product="contrast_enhancement.product != null ? contrast_enhancement.product : ''"
                  :categories="coating"
                  :recommendation="contrast_enhancement.id"
                  @select="contrastSelect"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    import ArrayUtils from "../../common/ArrayUtils";
import Constants from "../../common/Constants";
import Dropdown from "../../components/Profile-Recommendation/Dropdown.vue";
import CustomEditor from "../../components/custom/CustomEditor.vue";
import ConfigurationService from "../../service/ConfigurationService";
import LensConfigService from "../../service/LensConfigService";
import RecommendationService from "../../service/RecommendationService";

    export default {
        name: "Recommendation",
        components: {
            Dropdown,
            CustomEditor,
        },
        data() {
            return {
                remove_recommendation_text: Constants.REMOVE_RECOMMENDATION_TEXT,
                edit: (this.$store.state.survey.isSurveyEditing = false) && false,
                home_lens_under_45: [],
                ecp_lens_under_45: [],
                highly_optimized_pal: {},
                elife_pro_pal: {},
                mid_pal_and_degressive_lenses: [],
                protect_from_blue_light: {},
                protect_from_road_glare: {},
                polarized_lenses: {},
                photochromic_solution: {},
                contrast_enhancement: {},
                
                checkCategoryElife: '',

                single_vision: [],
                pal: [],
                e_life: [],
                pal_and_elife: [],
                coating: [],
                polashade: [],
                transitions: [],
                home_lens_design: [],

                e_life_config: {},
                soltes_distance: {},
                soltes_calculator: {},
                isDegressionEnabled: true,
                isCalculatorEnabled: true,
            };
        },
        computed: {
            isEditContent() {
                return this.edit ? "edit-th-content" : "normal-th-content";
            },
            isDegressionCheckboxDisabled() {
              if (!this.isDegressionEnabled || !this.isReadRecommended()) {
                return true;
              }
              return false;
              // for (var i = 0; i < this.mid_pal_and_degressive_lenses.length; i++) {
              //   if (this.mid_pal_and_degressive_lenses[i].recommendationOrder === Constants.LENS_SOLTES_ID) {
              //     if (this.mid_pal_and_degressive_lenses[i].productId === -1 || (this.mid_pal_and_degressive_lenses[i].product === null && this.mid_pal_and_degressive_lenses[i].productId === null)) {
              //       return true;
              //     }
              //     break;
              //   }
              // }
              // return false;
            },
            isCalculatorCheckboxDisabled() {
              if (!this.isCalculatorEnabled || !this.isReadRecommended()) {
                return true;
              }
              return false;
            }
        },
        mounted() {
            this.getListRecommendation();
            this.getCategory();
            this.getLensConfig();
        },
        updated() {
            //fixHeaderTable("table-1", "header-nav", this.edit);
        },
        methods: {
            isReadRecommended() {
              for (var i = 0; i < this.mid_pal_and_degressive_lenses.length; i++) {
                if (this.mid_pal_and_degressive_lenses[i].recommendationOrder === Constants.LENS_SOLTES_ID) {
                  if (this.mid_pal_and_degressive_lenses[i].productId === -1 || (this.mid_pal_and_degressive_lenses[i].product === null && this.mid_pal_and_degressive_lenses[i].productId === null)) {
                    return false;
                  }
                  break;
                }
              }
              return true;
            },
            allowNoRecommendation(id) {
              if (this.elife_pro_pal.id !== null) {
                if (this.elife_pro_pal.productId === -1 || (this.elife_pro_pal.product === null && this.elife_pro_pal.productId === null)) {
                  if (id === this.elife_pro_pal.id) {
                    return true;
                  } 
                } else {
                  if (id !== this.elife_pro_pal.id) {
                    return true;
                  } 
                }
              }
              for (var i = 0; i < this.mid_pal_and_degressive_lenses.length; i++) {
                if (this.mid_pal_and_degressive_lenses[i].productId === -1 || (this.mid_pal_and_degressive_lenses[i].product === null && this.mid_pal_and_degressive_lenses[i].productId === null)) {
                  if (id === this.mid_pal_and_degressive_lenses[i].id) {
                    return true;
                  } 
                } else {
                  if (id !== this.mid_pal_and_degressive_lenses[i].id) {
                    return true;
                  } 
                }
              }
              return false;
            },
            getLensConfig() {
                let surveyId = this.$store.state.survey.id;
                surveyId = surveyId <= 0 ? 1 : surveyId;
                LensConfigService.listConfig(surveyId)
                    .then((response) => {
                        var result = response.data.result;
                        this.e_life_config = result.elifeHomeParameter;
                        this.soltes_distance = result.soltesDistance;
                        if (!this.isDegressionEnabled) {
                          this.soltes_distance.value = false;
                        }
                        this.soltes_calculator = result.soltesCalculator;
                        if (!this.isCalculatorEnabled) {
                          this.soltes_calculator.value = false;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
            getListRecommendation() {
                let loader = this.$loading.show();
                let surveyId = this.$store.state.survey.id;
                surveyId = surveyId <= 0 ? 1 : surveyId;
                RecommendationService.listRecommendations(surveyId)
                    .then((response) => {
                        var result = response.data.result;
                        this.home_lens_under_45 = result.home_under45;
                        this.ecp_lens_under_45 = result.ecp_under45;
                        this.highly_optimized_pal = result.highly_optimized_pal;
                        this.elife_pro_pal = result.elife_pro_pal;
                        this.mid_pal_and_degressive_lenses = result.mid_pal_and_degressive_lenses;
                        this.protect_from_blue_light = result.protect_from_blue_light;
                        this.protect_from_road_glare = result.protect_from_road_glare;
                        this.polarized_lenses = result.polarized_lenses;
                        this.photochromic_solution = result.photochromic_solution;
                        this.contrast_enhancement = result.contrast_enhancement;
                        loader.hide();
                    })
                    .catch((e) => {
                        console.log(e);
                        loader.hide();
                    });
            },
            
            getCategory() {
                let surveyId = this.$store.state.survey.id;
                surveyId = surveyId <= 0 ? 1 : surveyId;
                ConfigurationService.select("RECOMMENDATION_PRODUCT_TYPE", surveyId)
                    .then((response) => {
                        var result = response.data.result;
                        this.checkCategoryElife = result.configuration.configValue;
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                RecommendationService.listCategory(surveyId)
                    .then((response) => {
                        var result = response.data.result;
                        // this.isDegressionEnabled = result.numberOfEnabledTypes == 4;
                        // this.isCalculatorEnabled = result.numberOfEnabledTypes > 1;
                        this.single_vision.push(result.singleVisionCategory);
                        this.pal.push(result.progressiveLensCategory);
                        if (this.checkCategoryElife == 'E_LIFE_PRO') {
                            this.e_life.push(result.voilaCategory);
                            this.isDegressionEnabled = result.numberOfEnabledTypesPro == 4;
                            this.isCalculatorEnabled = result.numberOfEnabledTypesPro > 1;
                        } else {
                            this.e_life.push(result.seriesCategory);
                            this.isDegressionEnabled = result.numberOfEnabledTypesSeries == 4;
                            this.isCalculatorEnabled = result.numberOfEnabledTypesSeries > 1;
                        }
                        if (!this.isDegressionEnabled) {
                          this.soltes_distance.value = false;
                        }
                        if (!this.isCalculatorEnabled) {
                          this.soltes_calculator.value = false;
                        }
                        this.pal_and_elife.push(result.progressiveLensCategory);
                        if (this.checkCategoryElife == 'E_LIFE_PRO') {
                            this.pal_and_elife.push(result.voilaCategory);
                        } else {
                            this.pal_and_elife.push(result.seriesCategory);
                        }
                        this.coating.push(result.coatingCategory);
                        this.polashade.push(result.polashade);
                        this.transitions.push(result.transitions);
                        this.makeHomeLens();
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
            makeHomeLens() {
                this.home_lens_design = JSON.parse(JSON.stringify(this.single_vision));
                for (var i = 0; i < this.home_lens_design.length; i++) {
                    var j = 0;
                    while (j < this.home_lens_design[i].products.length) {
                        if (
                            this.home_lens_design[i].products[j].consumerName != null &&
                            this.home_lens_design[i].products[j].consumerName != ""
                        ) {
                            j++;
                        } else {
                            this.home_lens_design[i].products.splice(j, 1);
                        }
                    }
                }
            },
            refresh() {
                this.items = ArrayUtils.clone(this.items);
            },
            turnOnEditable() {
                this.edit = true;
                this.$store.state.survey.editingScreenSaveFunc = this.save;
            },
            saveEdit(chainFunc) {
                var data = this.home_lens_under_45.concat(
                    this.ecp_lens_under_45,
                    this.highly_optimized_pal,
                    this.elife_pro_pal,
                    this.mid_pal_and_degressive_lenses,
                    this.protect_from_blue_light,
                    this.protect_from_road_glare,
                    this.polarized_lenses,
                    this.photochromic_solution,
                    this.contrast_enhancement,
                );
                var updateData = {};
                updateData.dtos = data;
                var config = [];
                config.push(this.e_life_config);
                config.push(this.soltes_distance);
                config.push(this.soltes_calculator);
                var configData = {};
                configData.dtos = config;


                let chain1 = undefined;
                if (chainFunc !== undefined) {
                  let completeCount = 0;
                  chain1 = () => {
                    completeCount ++;
                    if (completeCount === 2)
                      chainFunc();
                  };
                }
                RecommendationService.update(updateData)
                    .then((response) => {
                        if (chain1 !== undefined)
                          chain1()
                        var result = response.data.result;
                        console.log(result);
                        this.getListRecommendation();
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                console.log(configData);
                LensConfigService.update(configData)
                    .then((response) => {
                        if (chain1 !== undefined)
                          chain1()
                        var result = response.data.result;
                        console.log(result);
                        this.getLensConfig();
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                this.edit = false;
            },
            save(chainFunc){
              this.saveEdit(chainFunc);
            },
            turnOffEditable() {
              this.getListRecommendation();
              this.getLensConfig();
              this.edit = false;
            },
            homeSelect(name, consumerName, id, recommendationId) {
                for (var i = 0; i < this.home_lens_under_45.length; i++) {
                    if (this.home_lens_under_45[i].id == recommendationId) {
                        this.home_lens_under_45[i].productId = id;
                        this.home_lens_under_45[i].product.name = name;
                        this.home_lens_under_45[i].product.consumerName = consumerName;
                    }
                }
            },
            ecpSelect(name, consumerName, id, recommendationId) {
                for (var i = 0; i < this.ecp_lens_under_45.length; i++) {
                    if (this.ecp_lens_under_45[i].id == recommendationId) {
                        this.ecp_lens_under_45[i].productId = id;
                        this.ecp_lens_under_45[i].product.name = name;
                        this.ecp_lens_under_45[i].product.consumerName = consumerName;
                    }
                }
            },
            palSelect(name, consumerName, id) {
                this.highly_optimized_pal.productId = id;
                this.highly_optimized_pal.product.product.name = name;
                this.highly_optimized_pal.product.consumerName = consumerName;
            },
            elifeSelect(name, consumerName, id) {
                this.elife_pro_pal.productId = id;
                if (this.elife_pro_pal.product != null) {
                  this.elife_pro_pal.product.product.name = name;
                  this.elife_pro_pal.product.consumerName = consumerName;
                }
            },
            above45Select(name, consumerName, id, recommendationId) {
                for (var i = 0; i < this.mid_pal_and_degressive_lenses.length; i++) {
                  if (this.mid_pal_and_degressive_lenses[i].id == recommendationId) {
                    this.mid_pal_and_degressive_lenses[i].productId = id;
                    if (this.mid_pal_and_degressive_lenses[i].product != null) {
                      this.mid_pal_and_degressive_lenses[i].product.name = name;
                      this.mid_pal_and_degressive_lenses[i].product.consumerName = consumerName;
                    }
                    if (this.mid_pal_and_degressive_lenses[i].recommendationOrder === Constants.LENS_SOLTES_ID) {
                      if (id === -1) {
                        this.soltes_distance.value = false;
                        this.soltes_calculator.value = false;
                      }
                    }
                  }
                    // if (this.mid_pal_and_degressive_lenses[i].product != null && this.mid_pal_and_degressive_lenses[i].id == recommendationId) {
                    //     this.mid_pal_and_degressive_lenses[i].productId = id;
                    //     this.mid_pal_and_degressive_lenses[i].product.name = name;
                    //     this.mid_pal_and_degressive_lenses[i].product.consumerName = consumerName;
                    // } else if (this.mid_pal_and_degressive_lenses[i].product == null && this.mid_pal_and_degressive_lenses[i].id == recommendationId) {
                    //     this.mid_pal_and_degressive_lenses[i].productId = id;
                    //     if (this.mid_pal_and_degressive_lenses[i].product != null) {
                    //         this.mid_pal_and_degressive_lenses[i].product.name = name;
                    //         this.mid_pal_and_degressive_lenses[i].product.consumerName = consumerName;
                    //     }
                    // }
                }
            },
            blueLightSelect(name, consumerName, id) {
                this.protect_from_blue_light.productId = id;
                this.protect_from_blue_light.product.name = name;
                this.protect_from_blue_light.product.consumerName = consumerName;
            },
            roadGlareSelect(name, consumerName, id) {
                this.protect_from_road_glare.productId = id;
                this.protect_from_road_glare.product.name = name;
                this.protect_from_road_glare.product.consumerName = consumerName;
            },
            polashadeSelect(name, consumerName, id) {
                this.polarized_lenses.productId = id;
                this.polarized_lenses.product.name = name;
                this.polarized_lenses.product.consumerName = consumerName;
            },
            transitionsSelect(name, consumerName, id) {
                this.photochromic_solution.productId = id;
                this.photochromic_solution.product.name = name;
                this.photochromic_solution.product.consumerName = consumerName;
            },
            contrastSelect(name, consumerName, id) {
                this.contrast_enhancement.productId = id;
                this.contrast_enhancement.product.name = name;
                this.contrast_enhancement.product.consumerName = consumerName;
            },
        },

    };
</script>

<style scoped>
  .recommendation-header {
    margin: 16px 0px 62px 0px;
  }
  
  .content {
    padding: 24px;
    z-index: 1;
  }
  
  .show_info {
    width: 60%;
    padding: 15px;
    border: 1px solid rgb(190, 189, 189);
    border-radius: 5px;
  }
  
  table {
    margin-bottom: 20px;
  }
  
  /* The check-box */
  .check-box {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .check-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7.5px;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-box input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
  }

  .check-box input:disabled ~ .checkmark {
    background-color: grey;
    border: 1px solid black;
    border-radius: 3px;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .check-box input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .check-box .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 7px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .btn-tooltip {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }
  .dis_checkbox {
    pointer-events: none;
  }
  .tr_blue {
    background-color: #e5f4ff !important;
  }
  .tooltip-img {
    cursor: pointer;
  }
</style>
