export default {
    globalCountryCode: 'GLB',
    LENS_SLIDER_BUTTON_NAME: 'SLIDER_BUTTON_NAME',
    LENS_SLIDER_BUTTON_CONTENT: 'SLIDER_BUTTON_CONTENT',
    LENS_SLIDER_PASSWORD: 'SLIDER_PASSWORD',
    LENS_SLIDER_ALL_PACKAGES: 'All packages',
    LENS_SLIDER_ENTER_PASSWORD: 'Enter password',
    LENS_SLIDER_REMOVE_BTN_OPTION: 'SLIDER_REMOVE_BUTTON',
    COUNTERMAT_REMOVE_BTN_OPTION: 'COUNTERMAT_REMOVE_BUTTON',
    VISION_REMOVE_BUTTON_OPTION: 'VISION_REMOVE_BUTTON',

    LENS_SLIDER_MAX_SIZE_UPLOAD: 1000000,
    LENS_SLIDER_MAX_WIDTH_UPLOAD: 1641,
    LENS_SLIDER_MAX_HEIGHT_UPLOAD: 976,

    LENS_SLIDER_TYPE_ERROR: 1,
    LENS_SLIDER_MAX_SIZE_ERROR: 2,
    LENS_SLIDER_RESOLUTION_ERROR: 3,

    EMAIL_MAX_SIZE_UPLOAD: 1000000,
    EMAIL_MAX_WIDTH_UPLOAD: 1002,
    EMAIL_MAX_HEIGHT_UPLOAD: 900,

    COUNTERMAT_MASK_MAX_SIZE: 1000000,
    COUNTERMAT_MASK_MAX_WIDTH_UPLOAD: 500,
    COUNTERMAT_MASK_MAX_HEIGHT_UPLOAD: 100,

    COUNTERMAT_PAL_MAX_SIZE: 1000000,
    COUNTERMAT_PAL_MAX_WIDTH_UPLOAD: 1430,
    COUNTERMAT_PAL_MAX_HEIGHT_UPLOAD: 1949,

    COUNTERMAT_SV_MAX_SIZE: 1000000,
    COUNTERMAT_SV_MAX_WIDTH_UPLOAD: 1430,
    COUNTERMAT_SV_MAX_HEIGHT_UPLOAD: 1949,

    TIME_BEFORE_SESSION_EXPIRED_LOGOUT: 5,

    CATEGORY_MAX_SIZE_UPLOAD: 1000000,
    CATEGORY_MAX_WIDTH_UPLOAD: 1164,
    CATEGORY_MAX_HEIGHT_UPLOAD: 440,
    
    IMAGE_MAX_SIZE_UPLOAD: 1000000,
    IMAGE_MAX_WIDTH_UPLOAD: 1164,
    IMAGE_MAX_HEIGHT_UPLOAD: 440,

    ICON_MAX_SIZE_UPLOAD: 20000,
    ICON_MAX_WIDTH_UPLOAD: 144,
    ICON_MAX_HEIGHT_UPLOAD: 144,

    QUESTIONAIRE_IMAGE_MAX_SIZE_UPLOAD: 100000,
    QUESTIONAIRE_IMAGE_MAX_WIDTH_UPLOAD: 260,
    QUESTIONAIRE_IMAGE_MAX_HEIGHT_UPLOAD: 260,

    REMOVE_RECOMMENDATION_TEXT: 'Remove Recommendation',
    LENS_SOLTES_ID: 16,
}