/* eslint-disable no-console */
import { http } from "../plugin/http2-common";

class ConfigurationService {
  select(key, surveyId) {
    console.log(key)
    return http.get(`/survey-configuration/select/` + surveyId);
  }
  update(data) {
    return http.put(`/survey-configuration/update-one`, data);
  }
}

export default new ConfigurationService();
